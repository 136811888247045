<template>
  <ul class="badgesFilter">
    <li
      v-for="(option, index) in options"
      v-bind:key="index"
      v-bind:class="{
        'badgesFilter__item': true,
        'badgesFilter__item--active': modelValue === option,
      }"
    >
      <label class="badgesFilter__itemLabel">
        <input
          type="radio"
          name="badgesFilter"
          class="badgesFilter__itemInput"
          v-bind:value="option"
          v-model="fieldValue"
          v-on:change="$emit('update:modelValue', fieldValue)"
        />

        {{ option.label }}
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      validator: value => value.every(({ label, value }) => label !== undefined && value !== null ),
    },

    modelValue: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      fieldValue: this.modelValue,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.badgesFilter {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.badgesFilter__item {
  margin: 0 rem(10px) 0 0;

  &:last-child {
    margin: 0;
  }
}

.badgesFilter__itemLabel {
  @include label--bold;
  display: flex;
  align-items: center;
  padding: 0 18px;
  height: 38px;
  border-radius: 19px;
  background: $color-beige;
  cursor: pointer;
  transition: background 0.25s;

  .badgesFilter__item--active &,
  &:hover {
    background: $color-beige--dark;
  }
}

.badgesFilter__itemInput {
  visibility: hidden;
  width: 0;
  height: 0;
}
</style>