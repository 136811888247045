<template>
  <div class="badges">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column modifiers="sm-5">
            <h1 class="badges__title">
              {{ $t('badges.pageTitle') }}
            </h1>

            <p class="badges__body">
              {{ $t('badges.pageBody') }}
            </p>
          </base-column>

          <base-column
            v-if="latestBadge"
            modifiers="sm-5 sm-offset-2"
          >
            <div class="badges__latest">
              <div class="badges__latestContent">
                <div class="badges__latestTitle">
                  {{ $t('badges.latestBadgeTitle') }}
                </div>

                <div class="badges__latestName">
                  {{ latestBadge.name }}
                </div>

                <base-button
                  modifiers="primary small"
                  v-on:click="onBadgeClick(latestBadge)"
                >
                  <font-awesome-icon
                    icon="share-nodes"
                    class="badges__latestButtonIcon"
                  /> {{ $t('badges.latestBadgeButtonLabel') }}
                </base-button>
              </div>

              <img
                v-bind:src="latestBadge.image"
                v-bind:alt="latestBadge.name"
                class="badges__latestImage"
              />
            </div>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <badges-filter
              v-bind:options="filters"
              v-model="selectedFilter"
              class="badges__filters"
            />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <ul class="badges__list">
              <li
                v-for="badge in filteredBadges"
                v-bind:key="badge.id"
                class="badges__listItem"
              >
                <base-badge
                  v-bind:badge="badge"
                  v-on:click="onBadgeClick(badge)"
                />
              </li>
            </ul>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import BaseButton from '@/components/BaseButton';
import BadgesFilter from '@/components/badges/BadgesFilter';
import BaseBadge from '@/components/badges/BaseBadge';
import filters from '@/data/badgeFilters';
import { ModalBus } from '@/eventBus';

const BadgeModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/badges/BadgeModal' /* webpackChunkName: "badgeModal" */),
}));

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    BaseButton,
    BadgesFilter,
    BaseBadge,
  },

  data() {
    return {
      filters,
      selectedFilter: filters[0],
    };
  },

  computed: {
    ...mapState('badges', ['latestBadge']),
    ...mapGetters('badges', ['badges', 'achieved', 'unachieved']),

    filteredBadges() {
      if (this.selectedFilter.value === 'achieved') {
        return this.achieved;
      }

      if (this.selectedFilter.value === 'unachieved') {
        return this.unachieved;
      }

      return this.badges;
    },
  },

  created() {
    this.fetchBadges();
  },

  methods: {
    ...mapActions({
      fetchBadges: 'badges/fetchAll',
    }),

    onBadgeClick(badge) {
      ModalBus.emit('open', {
        component: BadgeModal,
        modifiers: 'badge',
        props: { badge },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.badges__title {
  @include heading-4;
  margin: 0 0 14px 0;
}

.badges__body {
  @include small;
  margin: 0 0 14px 0;

  @include desktop {
    margin: 0 0 40px 0;
  }
}

.badges__latest {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
  border-radius: 10px;
  background: $color-beige;
}

.badges__latestContent {
  flex: 1 1 auto;
  margin: 0 20px 0 0;
}

.badges__latestTitle {
  @include note;
  margin: 0 0 1px 0;
  color: rgba($color-black, 0.7);
}

.badges__latestName {
  @include lead--bold;
  margin: 0 0 16px 0;
  color: $color-black;
}

.badges__latestImage {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.badges__latestButtonIcon {
  margin: 0 14px 0 0;
  font-size: 18px;
}

.badges__filters {
  margin: 18px 0 0 0;

  @include desktop {
    margin: 0 0 28px 0;
  }
}

.badges__list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.badges__listItem {
  flex: 0 0 33.3%;
  margin: 0 0 15px 0;

  @include desktop {
    flex: 0 0 20%;
    margin: 0;
  }
}
</style>
