<template>
  <div v-bind:class="{
    'badge': true,
    'badge--achieved': !!badge.earned_at,
    'badge--unachieved': !badge.earned_at,
  }">
    <div class="badge__inner">
      <div class="badge__imageContainer">
        <img
          v-if="badge.earned_at"
          v-bind:src="badge.image"
          v-bind:alt="badge.name"
          class="badge__image"
        />

        <div
          v-if="!badge.earned_at"
          v-bind:class="{
            'badge__imagePlaceholder': true,
            'badge__imagePlaceholder--progress': !!badge.progress,
          }"
        >
          <font-awesome-icon
            v-if="!badge.progress"
            icon="lock"
            class="badge__imagePlaceholderIcon"
          />

          <template v-if="badge.progress">
            <span>
              {{ progressPercentage }}<span class="badge__imagePlaceholderUnit">%</span>
            </span>
          </template>
        </div>

        <canvas
          ref="canvas"
          width="112"
          height="112"
          class="badge__progressRingCanvas"
        />
      </div>

      <div class="badge__name">
        <div class="badge__nameLabel">
          {{ badge.name }}
        </div>

        <span
          v-if="badge.amount > 1"
          class="badge__amount"
        >
          x{{ badge.amount }}
        </span>
      </div>

      <div
        v-if="!!badge.earned_at"
        class="badge__share"
      >
        <font-awesome-icon
          icon="share-nodes"
          class="badge__shareIcon"
        /> Delen
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    badge: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      canvas: null,
      context: null,
      interval: null,
      intervalTime: 10,
      accTime: 0,
    };
  },

  computed: {
    progressPercentage() {
      const { goal, value } = this.badge.progress;

      return Math.round((value * 100) / goal);
    },
  },

  mounted() {
    if (this.badge.progress) {
      setTimeout(this.initProgressAnimation, 250);
    }
  },

  methods: {
    initProgressAnimation() {
      const percentage = this.progressPercentage;

      this.canvas = this.$refs.canvas;
      this.context = this.canvas.getContext('2d');
      this.interval = setInterval(this.progressInterval.bind(null, percentage), this.intervalTime);
    },

    progressInterval(percentage) {
      this.accTime += this.intervalTime;

      const accPercentage = this.easeInOutSine(this.accTime, 0, percentage, 1000);
      this.drawProgressBar(accPercentage);

      if (accPercentage === percentage) {
        clearInterval(this.interval);
      }
    },

    drawProgressBar(percentage) {
      const angleOffset = -(Math.PI / 2);
      const startAngle = 0 + angleOffset;
      const endAngle = ((percentage * (2 * Math.PI)) / 100) + angleOffset;

      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.beginPath();
      this.context.strokeStyle = '#797979';
      this.context.lineWidth = 3;
      this.context.lineCap = 'round';
      this.context.arc(this.canvas.width / 2, this.canvas.height / 2, (this.canvas.height / 2) - 2, startAngle, endAngle);
      this.context.stroke();
    },

    easeInOutSine(t, b, c, d) {
      // http://www.gizma.com/easing/
      // https://spicyyoghurt.com/tools/easing-functions
      return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.badge {
  padding: 25px 8px 15px 8px;
  border-radius: 15px;
  cursor: pointer;
  transition: background 0.25s;

  &:hover {
    background: $color-beige;
  }
}

.badge__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.badge__imageContainer {
  position: relative;
  margin: 0 auto 11px auto;
  width: 80px;
  height: 80px;

  @include desktop {
    margin: 0 0 15px 0;
    width: 100px;
    height: 100px;
  }
}

.badge__image,
.badge__imagePlaceholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.badge__imagePlaceholder {
  @include paragraph--bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  font-size: 20px;
  color: rgba($color-black, 0.4);
  background: rgba($color-grey--light, 0.5);

  &--progress {
    background: transparent;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba($color-grey--light, 0.5);
    }
  }
}

.badge__progressRingCanvas {
  position: absolute;
  top: -6px;
  left: -6px;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
}

.badge__imagePlaceholderIcon {
  font-size: 25px;
  color: $color-grey--light;
}

.badge__imagePlaceholderUnit {
  @include label;
}

.badge__name {
  @include desktop {
    margin: 0 0 13px 0;
  }
}

.badge__nameLabel {
  @include note--bold;
  margin: 0 0 5px 0;

  .badge--unachieved & {
    color: $color-grey--medium;
  }

  @include desktop {
    @include small--bold;

    .badge:hover & {
      text-decoration: underline;
    }
  }
}

.badge__amount {
  @include note--bold;
  margin: 0 0 0 3px;
  padding: 3px 8px;
  color: rgba($color-black, 0.7);
  border-radius: 12px;
  background: $color-beige;
  transition: background 0.25s;

  .badge:hover & {
    background: $color-beige--dark;
  }
}

.badge__share {
  display: none;

  @include desktop {
    @include tiny--bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: $color-green;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.25s, opacity 0.25s;

    .badge--unachieved & {
      display: none;
    }

    .badge:hover & {
      visibility: visible;
      opacity: 1;
    }
  }
}

.badge__shareIcon {
  margin: 0 6px 0 0;
  font-size: 16px;
}
</style>