import { i18n } from '@/plugins/i18n';

export default [
  {
    label: i18n.global.t('badges.filterLabelAll'),
    value: 'all',
  },
  {
    label: i18n.global.t('badges.filterLabelUnachieved'),
    value: 'unachieved',
  },
  {
    label: i18n.global.t('badges.filterLabelAchieved'),
    value: 'achieved',
  },
];